import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isLoggedIn: boolean = false;
  username: string = '';

  constructor(
    private authService: AuthService
  ) {
    this.authService.loginEmiter.subscribe(() => {
      this.checkIsLoggedIn();
    });
    this.authService.logoutEmiter.subscribe(() => {
      this.checkIsLoggedIn();
    })
   }

  ngOnInit(): void {
    this.checkIsLoggedIn();
  }

  checkIsLoggedIn(){
    this.isLoggedIn = this.authService.isLoggedIn();
    if(this.isLoggedIn){
      this.username = this.authService.getUsername();
    } else {
      this.username = '';
    }
  }

  logout(){
    this.authService.logout();
  }

}
