import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Jwt } from '../models/jwt';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly JWT_TOKEN = "JWT_TOKEN";
  private loggedUser: any;

  @Output() logoutEmiter = new EventEmitter<any>();
  @Output() loginEmiter = new EventEmitter<any>();

  constructor(
    private http: HttpClient,
    readonly router: Router,
    private jwtService: JwtHelperService
  ) { }

  loginFromSpid(token: string) {
    try {
      if (token && this.jwtService.decodeToken(token)) {
        const tokens: Jwt = { token: token };
        this.doLoginSpidUser(tokens);
        return true;
      } else {
        return false;
      }
    } catch (error: any) {
      alert(error.message);
      return false;
    }
  }

  /**
   * logout, si passa alla pagina login
   */
  logout() {
    this.doLogoutUser();
    this.router.navigate(["/"]);
  }

  isLoggedIn() {
    return !!this.getJwtToken();
  }

  getJwtToken() {
    let token = localStorage.getItem(this.JWT_TOKEN);
    return token != null ? token : undefined;
  }

  getUsername(): string {
    return this.jwtService.decodeToken(this.getJwtToken()).fiscalNumber;
  }

  private doLoginSpidUser(tokens: Jwt) {
    this.loggedUser = this.jwtService.decodeToken(tokens.token);
    this.storeTokens(tokens);
    this.loginEmiter.next(true);
  }

  private doLogoutUser() {
    this.loggedUser = null;
    this.removeTokens();
    this.loginEmiter.next(true);
  }

  private storeTokens(jwt: Jwt) {
    localStorage.setItem(this.JWT_TOKEN, jwt.token);
  }

  private removeTokens() {
    localStorage.removeItem(this.JWT_TOKEN);
  }

  initSpid() {
    const url = `${environment.SPIDAUTHSRV}/login?entityID={{idp}}&authLevel=SpidL2&target=${environment.target}&service=${environment.indexService}`;
    const config = {
      url: url,
      supported: [],
      extraProviders: [
        {
          "entityID": "xx_testenv2",
          "entityName": "TestEnv2 IdP"
        },
        {
          "entityID": "xx_validator",
          "entityName": "Test Saml Check"
        },
        
      ]
    }
    return config;
  }

  initCns() {
    const redirectUrl = window.location.href.split(/[?#]/)[0];
    const url = `${environment.SPIDAUTHSRV}/cns?redirectUrl=${redirectUrl}`;
    return url;
  }

  initCie() {
    const redirectUrl = window.location.href.split(/[?#]/)[0];
    const entityID = "xx_servizicie_test";
    const url = `${environment.SPIDAUTHSRV}/login?entityID=${entityID}&authLevel=SpidL2&redirectUrl=${redirectUrl}`;
    return url;
  }

  getErrorMessageSPID(code: string) {
    return this.http.get(
      `${environment.SPIDAUTHSRV}/spid/error-message?errorCode=${code}`
    ).pipe(map((response) => response)).toPromise();
  }
}
