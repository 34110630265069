<div class="it-header-slim-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="it-header-slim-wrapper-content">
            <a class="d-lg-block navbar-brand" routerLink="home">Servizio di test</a>
            <div class="it-header-slim-right-zone">
                <div *ngIf="isLoggedIn" class="nav-item dropdown">
                    <a class="nav-link btn btn-primary btn-icon btn-full" href="#" data-toggle="dropdown" aria-expanded="false">
                        <span class="rounded-icon">
                            <svg class="icon icon-primary">
                                <use xlink:href="/bootstrap-italia/svg/sprite.svg#it-user"></use>
                            </svg>
                        </span>
                        <span class="d-none d-lg-block">{{username}}</span>
                    </a>
                    <div class="dropdown-menu">
                      <div class="row">
                        <div class="col-12">
                          <div class="link-list-wrapper">
                            <ul class="link-list">
                              <li><a class="list-item" routerLink="profile"><span>Profilo</span></a></li>
                              <li><a class="list-item" href="#" (click)="logout()"><span>Logout</span></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                <a *ngIf="!isLoggedIn" routerLink="auth" class="btn btn-primary btn-icon btn-full">
                    <span class="rounded-icon">
                        <svg class="icon icon-primary">
                            <use xlink:href="/bootstrap-italia/svg/sprite.svg#it-user"></use>
                        </svg>
                    </span>
                    <span class="d-none d-lg-block">Accedi all'area personale</span>
                </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
